// import {
//   FacebookLoginProvider,
//   GoogleLoginProvider,
// } from 'angularx-social-login';
import {GoogleLoginProvider,FacebookLoginProvider,SocialLoginModule,SocialAuthServiceConfig} from '@abacritt/angularx-social-login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import {
//   SocialAuthServiceConfig,
//   SocialLoginModule,
// } from 'angularx-social-login';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { DeactivateGuard } from './user/timesheet-new/deactivate-guard';
import { DeactivateGuardReview } from './user/timesheet-review-new/deactivate-guard-review';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { RoundOffPipe } from 'src/app/user/pipes/round-off.pipe';
import { TokenInterceptor } from './Interceptor/http.interceptor';
import { EmailInviteComponent } from './email-invite/email-invite.component';
import { DeactivateGuardTimesheet } from './user/wireframe/timesheet-wireframe/deactivate-guard-timesheet';


@NgModule({
  declarations: [AppComponent, LoginComponent, ForgotPasswordComponent,EmailInviteComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    NgxEmojiPickerModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    PasswordStrengthMeterModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocialLoginModule,
  ],
  providers: [
    DatePipe,
    RoundOffPipe,
    DeactivateGuard,
    DeactivateGuardReview,
    DeactivateGuardTimesheet,

    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
                 // '727832792423-eu0jubeivma79f8elrproi6trslgjr2t.apps.googleusercontent.com',//anilllc client
                '727832792423-62qa77lref8fhhvqj1rub95t5b9bc5o3.apps.googleusercontent.com',//alberto client
                // '727832792423-brhq1eh41afnbc1a5n56hons8dtserf1.apps.googleusercontent.com',//optym client
                // '727832792423-hp5gt5msuvno8037auomqpmaqo6sl29n.apps.googleusercontent.com',//steer client
                //'316524600170-k84eplo7q57r0tpr19j6rhi34vpvamp0.apps.googleusercontent.com',//Local server
               //'193893527122-l47vto3gmf6ihi6tl2pm6af7d3imsq8p.apps.googleusercontent.com', //Test Server
                //'316524600170-ue8go18ubi3nnrns9i8tfvjbe3fqgs8h.apps.googleusercontent.com',  //liver server
               
            

              {
                oneTapEnabled: false,
                scopes: 'https://www.googleapis.com/auth/calendar.readonly'
              }
            ),
          },
          
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId'),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    BnNgIdleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
